import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { SemiBoldText } from 'components/common/text'

interface TabProps {
  menu: JSX.Element[]
  selectedMenuIndex: number
  onClickMenu: (index: number) => void
}

export const Tab: React.FC<TabProps> = (props) => {
  const { menu, selectedMenuIndex, onClickMenu } = props
  return (
    <TabMenuContainer>
      {menu.map((item, index) => (
        <TabMenu key={index} onClick={() => onClickMenu(index)}>
          <TabMenuText isSelected={index === selectedMenuIndex}>
            {item}
          </TabMenuText>
        </TabMenu>
      ))}
    </TabMenuContainer>
  )
}

export const TabContainer = styled.div`
  z-index: 1;
  top: 60px;
  width: 100%;
  position: fixed;
  background-color: ${(p) => p.theme.color.white1};
  @media ${(p) => p.theme.deviceSize.mobile} {
    border-bottom: 1px solid ${(p) => p.theme.color.line};
  }
`

export const TabMenuContainer = styled(Row)`
  gap: 24px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 16px;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none; // Chrome, Safari and Opera
    }
  }
`

export const TabMenu = styled(Row)`
  flex: none;
  padding-top: 20px;
  align-items: flex-end;
`

export const TabMenuText = styled(SemiBoldText)<{ isSelected: boolean }>`
  white-space: nowrap;
  height: 36px;
  color: ${(p) => (p.isSelected ? p.theme.color.black1 : p.theme.color.gray2)};
  font-size: ${(p) => p.theme.fontSize.desc};
  border-bottom: ${(p) =>
    p.isSelected ? `4px solid ${p.theme.color.primary}` : 0};
  cursor: pointer;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 14px;
  }
`
