import React, { Suspense, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'stores/globals'
import {
  usePositionDetail,
  useStartupDetail,
  useTempApplication,
} from 'apis/hooks'
import { PositionApplicationFormModal } from 'containers/positions/application/position-application-form-modal'
import { PositionApplicationStore } from 'stores/position-application'
import { PositionApplicationDoneModal } from 'containers/positions/application/position-application-done-modal'
import { PositionApplicationPreviewModal } from 'containers/positions/application/position-application-preview-modal'
import { ApplicationMessageCheckModal } from 'containers/positions/application/application-message-check-modal'
import { analytics } from 'infra/analytics'

export const PositionApplyModal = observer(() => {
  const { applyModalStore } = useStores()
  if (!applyModalStore.isOpen) return null

  return (
    <Suspense>
      <PositionApply
        startupId={applyModalStore.startupId}
        positionId={applyModalStore.positionId}
        onClose={() => applyModalStore.closeModal()}
      />
    </Suspense>
  )
})

const PositionApply: React.FC<{
  startupId: number
  positionId: number
  onClose: () => void
}> = observer(({ startupId, positionId, onClose }) => {
  const { current: store } = useRef(new PositionApplicationStore())
  const { data: startupDetail } = useStartupDetail(startupId)
  const { data: positionDetail, error } = usePositionDetail(positionId)
  const { data: tempApplication } = useTempApplication(positionId)

  useEffect(() => {
    analytics.traffic('position_apply_modal')
  }, [])

  useEffect(() => {
    if (error?.status === 404) onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    store.initTemp(tempApplication)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempApplication])

  useEffect(() => {
    if (positionDetail && !store.positionDetail)
      store.setState('positionDetail', positionDetail)
    if (startupDetail && !store.startupDetail)
      store.setState('startupDetail', startupDetail.curr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionDetail, startupDetail])

  if (!positionDetail || !startupDetail || tempApplication === undefined)
    return null
  return (
    <>
      {store.isOpenDoneModal ? (
        <PositionApplicationDoneModal store={store} onClose={onClose} />
      ) : store.isOpenPreviewModal ? (
        <Suspense>
          <PositionApplicationPreviewModal store={store} onClose={onClose} />
        </Suspense>
      ) : (
        <PositionApplicationFormModal store={store} onClose={onClose} />
      )}
      {store.isOpenMessageCheckModal && (
        <ApplicationMessageCheckModal store={store} />
      )}
    </>
  )
})
