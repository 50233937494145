import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { BaseText } from 'components/common/text'
import { Column, Row } from 'components/common/layout'
import { SKIP_POSITION_APPLICATION_CONTACT_KEY } from 'infra/constants'
import { PositionApplicationStore } from 'stores/position-application'
import { StartupBaseInfo } from 'components/startup/startup-base-info'
import { StartupTableInfo } from 'components/startup/startup-table-info'
import { StartupDetailInfo } from 'components/startup/startup-detail-info'
import { Card } from 'components/common/card'
import { PositionApplicationCheckContactStep } from 'containers/positions/application/position-application-check-contact-step'
import { PositionApplicationJoinReasonStep } from 'containers/positions/application/position-application-join-reason-step'
import { PositionApplicationFitReasonStep } from 'containers/positions/application/position-application-fit-reason-step'
import { PositionDetail } from 'components/positions/position-detail'
import { Modal } from 'components/common/modal'
import { isExpired } from 'utils/validations'
import {
  ApplicationStatusTab,
  PositionApplicationTab,
} from 'containers/positions/application/position-application-tab'
import { customToast } from 'components/common/toast'
import { useStores } from 'stores/globals'

export const PositionApplicationFormModal: React.FC<{
  store: PositionApplicationStore
  onClose: () => void
}> = observer(({ store, onClose }) => {
  const { positionListStore } = useStores()
  useEffect(() => {
    if (store.curStep === 0)
      store.setState(
        'curStep',
        isExpired(SKIP_POSITION_APPLICATION_CONTACT_KEY) ? 0 : 1,
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Modal
      width={1040}
      onClose={() => {
        onClose()
        customToast(
          '마이페이지 > 나의 활동에서 작성 중인 지원서를 확인할 수 있어요',
          { duration: 3000 },
        )
        store.mutateTemp()
        positionListStore.mutatePositionList()
      }}
      disableBackgroundClick
    >
      <ContentBox>
        <TitleText>
          <span>{store.startupDetail?.name}</span>의{' '}
          <span>
            {store.positionDetail?.name} · {store.positionDetail?.careerType}
          </span>
          에 지원하기
        </TitleText>
        <Column style={{ gap: 16 }}>
          <SectionWrapper>
            <InfoSection>
              <PositionApplicationTab store={store} />
              {store.curTab === 0 ? (
                <>
                  {store.positionDetail && (
                    <PositionDetail data={store.positionDetail} />
                  )}
                </>
              ) : (
                <>
                  {store.startupDetail && (
                    <>
                      <StartupBaseInfo data={store.startupDetail} />
                      <StartupTableInfo data={store.startupDetail} />
                      <StartupDetailInfo
                        data={store.startupDetail}
                        width={540}
                      />
                    </>
                  )}
                </>
              )}
            </InfoSection>
            <FormSection>
              {store.curStep !== 0 && (
                <Row style={{ gap: 6 }}>
                  <ApplicationStatusTab store={store} />
                </Row>
              )}
              {store.curStep === 0 ? (
                <PositionApplicationCheckContactStep store={store} />
              ) : store.curStep === 1 ? (
                <PositionApplicationJoinReasonStep store={store} />
              ) : (
                <PositionApplicationFitReasonStep store={store} />
              )}
            </FormSection>
          </SectionWrapper>
        </Column>
      </ContentBox>
    </Modal>
  )
})

const FormSection = styled(Column)`
  flex: 1;
  gap: 24px;
  height: 100%;
`

const SectionWrapper = styled(Row)`
  gap: 40px;
  align-items: start;
  height: calc(100vh - 220px);
  max-height: 800px;
`

const InfoSection = styled(Card)`
  gap: 24px;
  flex: none;
  width: 530px;
  height: 100%;
  padding: 20px;
  overflow: hidden scroll;
  justify-content: flex-start;
  background-color: ${(p) => p.theme.color.background3};
`

const ContentBox = styled(Column)`
  padding: 32px;
  gap: 40px;
`

const TitleText = styled(BaseText)`
  line-height: 1.4;
  font-size: ${(p) => p.theme.fontSize.h3};
  span {
    font-weight: ${(p) => p.theme.fontWeight.bold};
  }
`
