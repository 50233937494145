import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { DescText } from 'components/common/text'
import { useIsDesktopCSR } from 'utils/hooks'
import { useDashboardStartupDetail, useStartupUser } from 'apis/hooks'
import { StartupUser } from 'types/common'
import { useRouter } from 'next/router'
import { Avatar } from 'components/common/avatar'
import { isEndWithConsonant } from 'utils/validations'

const BANNER_HEIGHT = 55

export const getBannerHeight = (user: StartupUser | null | undefined) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent)
  if (user?.isAdmin) return 0
  if (
    (window.location.pathname.includes('chat-rooms') &&
      window.location.search.includes('proposalId') &&
      isMobile) ||
    !user
  )
    return 0
  return (!user.isPublished && !user.isInfoFillRequest) ||
    (!user.isPublishedStartupPosition && !user.isInfoFillRequest) ||
    !!user.pendingProposalCount ||
    !!user.curationUpdate?.recentCurationId ||
    user.newStartupPositionScoutSearch
    ? BANNER_HEIGHT
    : 0
}

export const DashboardHeaderBanner: React.FC = () => {
  const router = useRouter()
  const isDesktop = useIsDesktopCSR()
  const { data: user } = useStartupUser()
  const { data: startup } = useDashboardStartupDetail()
  const [contents, setContents] = useState<{
    description: string
    cta: string
    link: string
    image?: string
  } | null>(null)

  useEffect(() => {
    if (!user || !startup) return
    if (user.curationUpdate?.recentCurationId) {
      setContents({
        description: `${user.curationUpdate.managerName} 매니저가 ${
          startup.name
        }${
          isEndWithConsonant(startup.name || '') ? '을' : '를'
        } 위해 추천한 인재가 도착했어요!`,
        cta: '확인하러 가기',
        link: `/dashboard/scouts/recommended?recentCurationId=${user.curationUpdate.recentCurationId}`,
        image: user.curationUpdate.managerImage || undefined,
      })
      return
    }
    if (user.pendingProposalCount) {
      setContents({
        description: `${
          user.pendingProposalCount || 'n'
        }건의 메시지가 발송 대기중이에요. 기업 상세 페이지가 작성 완료되는대로 바로 발송해드려요!`,
        cta: '상세 페이지 작성하기',
        link: '/dashboard/startup',
      })
      return
    }
    if (user?.newStartupPositionScoutSearch) {
      setContents({
        description: `${startup.name}${
          isEndWithConsonant(startup.name || '') ? '이' : '가'
        } 찾는 "${
          user.newStartupPositionScoutSearch.name
        }" 포지션에 딱 맞는 인재가 도착했어요!`,
        cta: '채용공고 추천인재 보러 가기',
        link: `/dashboard/scouts/search/position-curation/${user.newStartupPositionScoutSearch.id}`,
      })
      return
    }
    if (user?.isInfoFillRequest) return
    // 토스하기 요청했으면 안띄어도 되는것들
    if (!user.isPublished) {
      setContents({
        description: `아직 기업 상세 페이지가 입력되지 않았어요. ${
          isDesktop
            ? '매력적인 상세 페이지를 입력해서 인재의 관심을 끌어보세요!'
            : ''
        }`,
        cta: '상세 페이지 작성하기',
        link: '/dashboard/startup',
      })
      return
    }
    if (!user.isPublishedStartupPosition) {
      setContents({
        description: `아직 공개된 채용 공고가 없어요. ${
          isDesktop
            ? '채용 공고를 올려 우리 기업에 관심 있는 인재로부터 채용 지원을 받아보세요!'
            : ''
        }`,
        cta: '채용 공고 올리기',
        link: '/dashboard/positions',
      })
      return
    }
  }, [user, startup, isDesktop])
  if (getBannerHeight(user) === 0) return null

  return (
    <Main>
      {contents && (
        <>
          {contents.image && <Avatar size={28} src={contents.image} />}
          <Text>{contents.description}</Text>
          <Text id='link' onClick={() => router.push(contents.link)}>
            👉🏻 {isDesktop ? contents.cta : '이동'}
          </Text>
        </>
      )}
    </Main>
  )
}

const Main = styled(Row)`
  gap: 16px;
  width: 100%;
  height: ${BANNER_HEIGHT}px;
  justify-content: center;
  border-bottom: 1px solid ${(p) => p.theme.color.line};
  background-color: ${(p) => p.theme.color.white1};
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 0 20px;
    justify-content: space-between;
  }
`

const Text = styled(DescText)`
  &#link {
    white-space: nowrap;
    text-decoration: underline;
    font-weight: ${(p) => p.theme.fontWeight.semiBold};
    cursor: pointer;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
    line-height: 18px;
  }
`
