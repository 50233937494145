import React from 'react'
import { StartupDetail } from 'types/common'
import { useIsLoggedIn, useStartupIsInterestedUpdate } from 'utils/hooks'
import { useStores } from 'stores/globals'
import { Bookmark } from 'components/common/bookmark'
import { Column, Row } from 'components/common/layout'
import { BaseText, BoldText, DescText } from 'components/common/text'
import { Hashtag } from 'components/common/hashtag'
import styled from 'styled-components'
import { setStartupIsInterested } from 'utils/handlers'
import { StartupLogo } from 'components/startup/startup-logo'
import { analytics } from 'infra/analytics'
import { useBaseInfo } from 'apis/hooks'
import { getLocationName } from 'utils/displays'
import { getDiffFromPast } from 'utils/formatters'
import { Tag, TagSize, TagType } from 'components/common/tag'
import { ChipWrapper } from 'components/common/chip'

// 스타트업 상단 기본정보 컨테이너
export const StartupBaseInfo: React.FC<{
  data: StartupDetail
}> = ({ data }) => {
  const isLoggedIn = useIsLoggedIn()
  const { data: baseInfo } = useBaseInfo()
  const { loginStore } = useStores()
  const openLoginModal = () => loginStore.setIsModalOpen(true)
  useStartupIsInterestedUpdate([data.id], `/startups/${data.id}`)
  const BookmarkComponent = (
    <Bookmark
      onClick={() => {
        if (!isLoggedIn) return openLoginModal()
        setStartupIsInterested(data.id, data.isInterested)
        analytics.track('compDetail_jsu_click_saveBtn_comp', {
          startupId: data.id,
          startupName: data.name,
        })
      }}
      isBookmarked={data.isInterested}
    />
  )

  const locationName = getLocationName(data.location, baseInfo?.locations || [])

  return (
    <>
      <StartupBaseInfoDesktop>
        <Column style={{ flex: 1, gap: 8 }}>
          <StartupLogo src={data.thumbnail} size={90} mobileSize={44} />
          <ChipWrapper>
            <StartupName>{data.name}</StartupName>
            <SubText>{locationName}</SubText>
            {getDiffFromPast(data.createdAt) <= 14 && <NewTag />}
          </ChipWrapper>
          {data.tags.length > 0 && <Hashtag data={data.tags} />}
          <DescText>{data.briefIntro}</DescText>
        </Column>
        <Column style={{ marginTop: 8 }}>{BookmarkComponent}</Column>
      </StartupBaseInfoDesktop>
      <StartupBaseInfoMobile>
        <Row
          style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
        >
          <Column style={{ gap: 10 }}>
            <StartupLogo src={data.thumbnail} size={90} mobileSize={44} />
            <ChipWrapper>
              <StartupName>{data.name}</StartupName>
              {getDiffFromPast(data.createdAt) <= 14 && <NewTag />}
            </ChipWrapper>
          </Column>
          {BookmarkComponent}
        </Row>
        {data.tags.length > 0 && <Hashtag data={data.tags} />}
        <BaseText>{data.briefIntro}</BaseText>
        <SubText>{locationName}</SubText>
      </StartupBaseInfoMobile>
    </>
  )
}

const NewTag = () => (
  <Tag variant={TagType.BLUE} size={TagSize.EXTRA_SMALL}>
    신규 등록
  </Tag>
)

const StartupName = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h3};
  }
`

const StartupBaseInfoDesktop = styled(Row)`
  align-items: flex-start;
  @media ${(p) => p.theme.deviceSize.mobile} {
    display: none;
  }
`

const StartupBaseInfoMobile = styled(Column)`
  gap: 10px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    display: none;
  }
`

const SubText = styled(BaseText)`
  color: ${(p) => p.theme.color.gray2};
`
