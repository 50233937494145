/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import 'styles/reset.css'
import 'styles/globals.css'
import React, { useEffect } from 'react'
import Script from 'next/script'
import Head from 'next/head'
import TagManager from 'react-gtm-module'
import type { AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { theme } from 'infra/theme'
import {
  CHANNEL_IO_PLUGIN_KEY,
  FUNNEL_KEY,
  GOOGLE_TAG_MANAGER_ID,
  HOTJAR_ID,
  IS_APP,
  IS_BROWSER,
  IS_PRODUCTION,
} from 'infra/constants'
import { analytics } from 'infra/analytics'
import { SWRConfig } from 'swr'
import { StoresProvider } from 'stores/globals'
import { useRouter } from 'next/router'
import { hotjar } from 'react-hotjar'
import { LoginVerifier } from 'infra/login-verifier'
import { LoginModal } from 'components/misc/login'
import { Toaster } from 'react-hot-toast'
import { StartupModal } from 'components/startup/startup-modal'
import { PositionModal } from 'components/positions/position-modal'
import { ErrorBoundary } from 'components/misc/error-boundary'
import { PositionApplyModal } from 'components/positions/position-apply-modal'
import { AutoProposalMessageFloat } from 'components/dashboard/scouts/proposal/auto-proposal-message-float'
import { ChannelService } from 'infra/channel'
import { commonToastStyle } from 'components/common/toast'
import { LocationSelectorModal } from 'components/misc/location-selector-modal'
import { useIsDesktop } from 'utils/hooks'
import { ProfileFillNeedModal } from 'components/scouts/request-form/profile/profile-fill-need-modal'
import { AppInstallPromptPopUp } from 'components/misc/app-install-prompt-pop-up'
import { PositionApplyPreconditionModal } from 'containers/positions/position-apply-precondition-modal'
import { PositionSelectorModal } from 'components/misc/position-selector-modal'

let isAnalyticsInitialized = true
const channelService = new ChannelService()

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const isDesktop = useIsDesktop()
  if (IS_BROWSER && isAnalyticsInitialized) {
    // 초기실행
    // NOTE(son) https://www.learnbestcoding.com/post/9/easiest-way-to-integrate-google-analytics-with-react-js-and-next-js
    if (IS_PRODUCTION) {
      hotjar.initialize(HOTJAR_ID, 1)
      if (!router.pathname.includes('admin') && isDesktop && !IS_APP) {
        channelService.boot({
          pluginKey: CHANNEL_IO_PLUGIN_KEY,
        })
      }
    }
    TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID as string })
    // NOTE(son): useEffect로 실행 시 track을 읽을 수 없는 에러가 나타남, 그래서 initialClient로 중복호출 제거함
    analytics.init()
    isAnalyticsInitialized = false
  }

  const funnel = router.query.funnel
  const content = (
    <React.Suspense>
      <div id='main'>
        <Component {...pageProps} />
      </div>
      <StartupModal />
      <PositionModal />
      <LoginModal />
      <PositionApplyPreconditionModal />
      <PositionApplyModal />
      <LocationSelectorModal />
      <PositionSelectorModal />
      <ProfileFillNeedModal />
      <AutoProposalMessageFloat />
      {!router.pathname.includes('contents') && <AppInstallPromptPopUp />}
      <Toaster
        toastOptions={{ style: commonToastStyle }}
        containerStyle={{ top: 70 }}
      />
    </React.Suspense>
  )

  useEffect(() => {
    if (funnel) localStorage.setItem(FUNNEL_KEY, JSON.stringify(funnel))
  }, [funnel])
  return (
    <>
      <Head>
        <title>그룹바이 | 스타트업 채용 플랫폼</title>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
      </Head>
      <Script src='/avoid-in-app.js' strategy='beforeInteractive' />
      <Script
        type='text/javascript'
        src='//wcs.naver.net/wcslog.js'
        strategy='afterInteractive'
      />
      <Script
        id='plain-javascript'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          document.addEventListener('DOMContentLoaded', function() {
            var _nasa={}; //초기화구문
            window.wcs_add={}; // react native web view에서 에러가 발생해서 초기화구문 추가
            if (!window.wcs_add.wa) window.wcs_add = { wa: "s_511a07378f8b"};
            if (!_nasa) var _nasa={};
            if(window.wcs){
              wcs.inflow();
              wcs_do(_nasa);
              var _nasa={}; //초기화구문
            }
          });
          `,
        }}
      />
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <SWRConfig value={{ suspense: true }}>
            <StoresProvider>
              {router.pathname.includes('/admin') ? (
                content
              ) : (
                <LoginVerifier>{content}</LoginVerifier>
              )}
            </StoresProvider>
          </SWRConfig>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  )
}
