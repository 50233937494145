import { makeAutoObservable } from 'mobx'

export class DashboardPositionCurationStore {
  isTooltipClosed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsTooltipClosed(v: boolean) {
    this.isTooltipClosed = v
  }
}
