import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FiX } from 'react-icons/fi'
import { Column, Row } from 'components/common/layout'
import { Card } from 'components/common/card'
import { observer } from 'mobx-react'
import { BoldText } from 'components/common/text'

export const Modal: React.FCC<{
  width: number
  laterBtnText?: string
  disableCloseBtn?: boolean
  disableBackgroundClick?: boolean
  onLater?: () => void
  onClose?: (e?: React.MouseEvent) => void
}> = observer(
  ({
    children,
    width,
    laterBtnText,
    disableCloseBtn,
    disableBackgroundClick,
    onLater,
    onClose,
  }) => {
    useEffect(() => {
      window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && !disableBackgroundClick) onClose?.()
      })
      const body = document.body
      body.style.overflowY = 'hidden'
      return () => {
        window.removeEventListener('keydown', (e) => {
          if (e.key === 'Escape' && !disableBackgroundClick) onClose?.()
        })
        body.style.overflow = ''
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
      <Background
        onClick={(e) => {
          e.stopPropagation()
          if (!disableBackgroundClick) onClose?.(e)
        }}
      >
        <ModalBackgroundWrapper>
          <ModalCard width={width} onClick={(e) => e.stopPropagation()}>
            <ModalHeader>
              {!disableCloseBtn && (
                <FiX size={24} onClick={onClose} strokeWidth={1} />
              )}
            </ModalHeader>
            <Content>{children}</Content>
          </ModalCard>
          <OutSideBtnText onClick={onLater}>{laterBtnText}</OutSideBtnText>
        </ModalBackgroundWrapper>
      </Background>
    )
  },
)

const ModalBackgroundWrapper = styled(Column)`
  gap: 24px;
  width: 100%;
  align-items: center;
`

const OutSideBtnText = styled(BoldText)`
  color: ${(p) => p.theme.color.white1};
  text-decoration: underline;
  cursor: pointer;
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
`

export const Background = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  position: fixed;
  overflow: hidden;
  background-color: ${(p) => p.theme.color.black2}88;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 20px;
  }
`

const ModalCard = styled(Card)<{ width: number }>`
  width: ${(p) => p.width}px;
  max-height: calc(100vh - 40px);
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    margin: 0 auto;
    max-height: calc(100vh - 120px); // dvh가 지원되지 않는 경우를 대비하여
    max-height: calc(100dvh - 40px);
  }
`

const Content = styled.div`
  overflow: hidden scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const ModalHeader = styled(Row)`
  top: 16px;
  right: 16px;
  z-index: 1;
  position: absolute;
  svg {
    cursor: pointer;
  }
`
